import 'StyleSheets/pharmacies/application/index';
import { successToast } from './index/successToast';
import { insertErrorMessage } from './index/insertErrorMessage';
import { checkPasswordConfirm } from './index/checkPasswordConfirm';

document.addEventListener('turbolinks:before-visit', function () {
  // 非常に苦しい処理.
  // WebSocketを確立させるために、ajax(turbolinks)で遷移させたくない.
  $(document).off('ajax:success', '#pharmacy-login-form');
  $(document).off('ajax:error', '#pharmacy-login-form');
  $('form#pharmacy-create-form').off('submit');
  $(document).off('ajax:error', '#pharmacy-create-form');
});

document.addEventListener('turbolinks:load', function () {
  // 非常に苦しい処理.
  // WebSocketを確立させるために、ajax(turbolinks)で遷移させたくない.
  $(document).on('ajax:success', (event) => successToast(event));
  $(document).on('ajax:error', '#pharmacy-login-form', (event) => insertErrorMessage(event));
  $('form#pharmacy-create-form').on('submit', (event) => checkPasswordConfirm(event));
  $(document).on('ajax:error', '#pharmacy-create-form', (event) => insertErrorMessage(event));
});
