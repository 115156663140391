export const successToast = (event) => {
  let message;
  /* eslint-disable no-prototype-builtins */
  if (event.originalEvent.detail[0].hasOwnProperty('message')) {
    message = event.originalEvent.detail[0].message[0];
  } else {
    window.location.href = '/pharmacies/accounts';
    return;
  }

  // Toast
  let toastElement = document.getElementById('toastElement');
  let toast_body = document.getElementById('toast_body');
  toast_body.innerHTML = message;
  toastElement.classList.remove('d-none');
  let toast = new bootstrap.Toast(toastElement);
  toast.show();
};
